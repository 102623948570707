<template>
  <div class="view-header view-header--protocol-details" v-if="pageTitle">
    <div class="view-header__header">
      <Btn v-if="
        backRouteName != 'protocolsShare' &&
        (
          protocol?.partage_accepte === true || protocol?.entite_parent === null
        ) &&
        helperService.userHasPermission('protocole_edit') &&
        protocol?.statut?.uid !== 'ENCOURS'
      " class="action-btn" icon="create" color="white" hollow text="Modifier" :to="{
          name: `${$route.name}Edit`,
          params: {
            id: $route.params.id
          }
        }" />

      <Btn v-if="
        backRouteName != 'protocolsShare' &&
        protocol?.partage_accepte === true &&
        helperService.userHasPermission('protocole_edit') &&
        protocol?.statut?.uid === 'ENCOURS' &&
        $route.name.includes('protocolStudiedExperiment')
      " class="action-btn" icon="create" color="white" hollow text="Modifier" :to="{
          name: `${$route.name}Edit`,
          params: {
            id: $route.params.id
          }
        }" />

    </div>
    <div class="view-header__footer">
      <BtnBack :key="componentKey" :to="{ 'name': backRouteName }" />
      <h1 class="page-title">Protocole - {{ protocol?.titre }}</h1>
    </div>
  </div>

  <div class="view-body">
    <Section class="section section--no-padding">
      <TabsMaterial :tabs="[
        { urlName: 'protocolGeneralInformation', text: 'Informations générales' },
        { urlName: 'protocolExperimentImplementation', text: 'Implantation de l\'essai' },
        { urlName: 'protocolStudiedExperiment', text: 'Facteurs étudiés' },
        { urlName: 'protocolCoverageExperiment', text: 'Couverture sur l\'essai' },
        { urlName: 'protocolPhytosanitaryProducts', text: 'Produits phytosanitaires' },
        { urlName: 'protocolStatisticalTests', text: 'Tests statistiques' },
        { urlName: 'protocolRatingCalendar', text: 'Calendrier des notations' },
        { urlName: 'protocolVersionsAndValidation', text: 'Versions et validations' },
      ]" />

      <router-view v-if="protocol" :protocol="protocol"></router-view>

    </Section>
  </div>

  <NavigationDropdownProtocol v-if="protocol" :protocol="protocol" />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import NavigationDropdownProtocol from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'

export default {
  name: 'ProtocolsView',

  components: {
    NavigationDropdownProtocol,
    Btn,
    BtnBack,
    Section,
    TabsMaterial,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'protocolsShare') {
        // eslint-disable-next-line no-param-reassign
        vm.backRouteName = from.name
        // eslint-disable-next-line no-param-reassign
        vm.componentKey += 1
      }
    })
  },

  data() {
    return {
      protocol: null,
      backRouteName: 'protocols',
      componentKey: 0,
    }
  },

  created() {
    this.getProtocol()
  },

  methods: {
    getProtocol() {
      return this.fetchService.get(`protocole/${this.$route.params.id}`).then((response) => {
        this.protocol = response.data
      })
    },
  },
}
</script>

<style scoped></style>
