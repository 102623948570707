<template>
  <NavigationDropdown
    :items-data="[
      {
        key: 'protocol-details',
        route: {
          name: 'protocol',
          params: {
            id: $route.params.id,
          },
          meta: {
            permissions: 'protocol_show',
          },
        },
        label: 'Fiche protocole',
        iconName: 'square',
      },
      {
        key: 'protocol-sharing',
        route: {
          name: 'protocolShared',
          params: {
            id: $route.params.id,
          },
          meta: {
            permissions: 'protocole_children',
          },
        },
        label: 'Partages',
        iconName: 'square',
      },
      {
        key: 'protocol-files',
        route: {
          name: 'protocolFiles',
          params: {
            id: $route.params.id,
          },
          meta: {
            permissions: 'fichier_list,protocolefichier_show',
          },
        },
        label: 'Fichiers',
        iconName: 'square',
      },
      {
        key: 'protocol-grouping',
        route: {
          contains: 'grouping',
          name: 'protocolEssaisGroupings',
          params: {
            id: $route.params.id,
          },
          meta: {
            permissions: 'protocol_show',
          },
        },
        label: 'Regroupements d\'essais',
        iconName: 'square',
      },
    ].concat(essais)"
  >
  </NavigationDropdown>
</template>

<script>
import NavigationDropdown from '@/components/layout/NavigationDropdown.vue'

export default {
  name: 'NavigationDropdownProtocol',

  components: {
    NavigationDropdown,
  },

  props: {
    protocol: {
      type: Object,
    },
  },

  data() {
    return {
      essais: [],
    }
  },

  mounted() {
    if (
      false
      && this.helperService.userHasPermission('essai_list')
      && this.protocol?.statut?.uid === 'ENCOURS'
    ) {
      this.essais.push({
        key: 'essais',
        route: {
          path: '/essais',
          query: {
            filters: `protocole:${this.protocol?.id}|${this.protocol?.titre}`,
          },
          meta: {
            permissions: 'essai_list',
          },
        },
        label: 'Essais',
        iconName: 'square',
      })
    }
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
</style>
